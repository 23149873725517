import * as React from "react"
import { useState } from "react";
import Helmet from "react-helmet";
import Contact from "../components/common/contact";
import Hero from "../components/common/hero"
import Testimonials from "../components/common/testimonials";
import Footer from "../components/footer/footer";
import Header from "../components/header/header"
import Tabs from "../components/services/tabs";

// Images
import HeroImage from "../images/bg-main-3.webp";

// markup
const IndexPage = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <>
     <Helmet>
        <meta charSet="utf-8" />
        <title>The Detailing Ghost | Fully Mobile | We Come To You</title>
        <meta name="description" content="Give your car that extra care and protection it truly deserves. We provide premium car cleaning &amp; detailing services. Give us a call to book yours today." />
        <link rel="canonical" href="https://thedetailingghost.co.nz" />
      </Helmet>
      <Header />
      <Hero
        backgroundImage={HeroImage}
        title="Welcome to The Detailing Ghost"
        description="Fully Mobile. We Come to You"
        setActiveTab={setActiveTab}
        actions={[
          {
            title: 'Get in touch',
            link: '#contact',
            style: 'primary'
          },
          {
            title: 'Ceramic Paint Protection',
            link: '#services',
            style: 'secondary',
            tab: 'paint'
          }
        ]}
      />
      <main className="main">
       
        {/* Services + Prices Tabs */}
        <Tabs setActiveTab={setActiveTab} activeTab={activeTab} />
        {/* Testimonials */}
        <Testimonials />

        {/* Contact details block */}
        <Contact />
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
