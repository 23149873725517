import React from "react";

const Hero = (props) => {
    return (
        <div className="hero" style={{backgroundImage: `url(${props.backgroundImage})`}}>
            <div className="hero__content p-2">
                {props.title &&
                    <h1 className="hero__title text-3xl md:text-5xl mb-5">{props.title}</h1>
                }
                {props.description &&
                    <h3 className="hero__description mb-7">{props.description}</h3>
                }
                {props.actions &&
                    <div className="hero__actions">
                        {props.actions.map((action) => {
                            return (<a className={'mb-2 mx-2 button button--' + action.style} href={action.link} onClick={action.tab && action.tab === 'paint' ? () =>   props.setActiveTab(2) : undefined}>{action.title}</a>)
                        })}
                    </div>
                }
            </div>
            <video className="hero__video"  autoPlay loop muted >
            <source src={'https://billing.thedetailingghost.co.nz/videos/video-intro.mp4'} type="video/mp4" />
            </video>
        </div>
    )
}

export default Hero;