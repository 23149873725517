import React from "react"
import {Swiper, SwiperSlide} from "swiper/react"
import {Autoplay, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/pagination';

const Testimonials = () => {

  return (
        <div className="py-10 bg-primary" id="testimonials">
            <div className="container">
            <h2 className="heading text-center">Testimonials</h2>
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              autoplay={{delay: 3000}}
            >
            <SwiperSlide className="text-center">
                <blockquote className="relative p-4 text-xl italic bg-neutral-100 text-neutral-600 quote">
                <p className="mb-4">Very friendly staff and professional service. They went out of the way in providing best service possible. Highly recommended. Many thanks to Karan, Ravi  and whole team for best car coating experience.</p>
                <h3 className="text-xl">- Gurmilap Singh</h3>
                </blockquote>
              </SwiperSlide>
                <SwiperSlide className="text-center">
                <blockquote className="relative p-4 text-xl italic bg-neutral-100 text-neutral-600 quote">
                <p className="mb-4">We booked this company to detail our 2 company vehicles, superb job I highly recommend. Will definently book again to detail the rest of our fleet on a regular basis.</p>
                <h3 className="text-xl">- Kristy Warren</h3>
                </blockquote>
              </SwiperSlide>
              <SwiperSlide className="text-center">
                <blockquote className="relative p-4 text-xl italic bg-neutral-100 text-neutral-600 quote">
                <p className="mb-4">Excellent service by Karan and Ravi, give a booking to see the result I have done mine. All cars from them it’s unbelievable results. Finally , thanks for give time and get my car looks new again after long time.</p>
                <h3 className="text-xl">- Sunny Ranu</h3>
                </blockquote>
              </SwiperSlide>
              <SwiperSlide className="text-center">
                <blockquote className="relative p-4 text-xl italic bg-neutral-100 text-neutral-600 quote">
                <p className="mb-4">Did an excellent job on my SUV.My car never looked so new. Highly recommend!!</p>
                <h3 className="text-xl">- James</h3>
                </blockquote>
              </SwiperSlide>
              </Swiper>
            </div>
        </div>
  )
}

export default Testimonials