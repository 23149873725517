import React from "react";
const PriceBlock = (props) => {
  return (
    <div className="priceBlock flex-1 border-gray-50 mb-4">
      {props.title && <h3 className="text-3xl font-bold mb-4 px-4 serif colour-primary">{props.title}</h3>}
      {props.priceLarge && <h4 className="px-4 text-4xl "><span className="">$</span>{props.priceLarge}<sup className="top-0">.00</sup></h4>}
      {props.prices &&
        <div className="mb-4 px-4">
         { props.prices.map((block) => {
            return (
              <div className="priceBlock__price flex justify-between">
                <div className="priceBlock__price__title font-serif text-lg">{block.vehicle}</div>
                <div className="priceBlock__price__value font-bold font-serif text-lg">{typeof block.amount === "number" && "$"}{block.amount}{typeof block.amount === "number" && ".00"}</div>
              </div>
            );
        })}
      </div> }

      <ul className="priceBlock__service">
        {props.services &&
          props.services.map((service) => {
            return <li className="px-4 flex py-1"><svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6 mr-2 stroke-primary" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
          </svg> {service}</li>;
          })}
      </ul>
    </div>
  );
};

export default PriceBlock;
