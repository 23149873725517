import React from "react";
import Icon1 from "../../../assets/icons/icon-1.svg";
import Icon2 from "../../../assets/icons/icon-2.svg";
import Icon3 from "../../../assets/icons/icon-3.svg";
import Icon4 from "../../../assets/icons/icon-4.svg";
import PriceBlock from "../common/priceBlock";


const Tabs = (props) => {

  const tabs = [
    {
      title: "Car Grooming",
      icon: Icon1,
      content: "Coming Soon...",
    },
    {
      title: "Paint Protection & Ceramic Coating",
      icon: Icon3,
    },
    {
      title: "Digger / Truck Cleaning",
      icon: Icon4,
    },
    {
      title: "Boat / RV Grooming",
      icon: Icon2,
    },

  ];

  return (
    <div className="services" id="services">
      <div className="services__container  mb-2">
        <div className="services__tabs">
          <div className="services__tabs__container">
            {tabs.map((item, index) => {
              return (
                <>
                  <a
                    onClick={() => props.setActiveTab(index)}
                    className={`services__tab  ${
                      props.activeTab === index ? "services__tab--active" : ""
                    }`}
                  >
                    <item.icon className="services__tabs__icon" />
                    <span>{item.title}</span>
                  </a>
                </>
              );
            })}
          </div>
        </div>
        <div className="services__content py-10">
          <div className="container">
            {/* Tab 1 */}
            {props.activeTab === 0 && (
              <>
                <div className="services__content__inner md:flex md:-mx-2">
                  <PriceBlock
                    title="Silver"
                    prices={[
                      {
                        vehicle: "Small Car",
                        amount: 85.0,
                      },
                      {
                        vehicle: "Medium Car",
                        amount: 100.0,
                      },
                      {
                        vehicle: "Large Car / SUV",
                        amount: 120.0,
                      },
                    ]}
                    services={[
                      "Hand Wash",
                      "Gloss Finish",
                      "Mag Wheels Cleaned & Dressed",
                      "Door Frames",
                      "Vacuum Interior",
                      "Dashboard Cleaned & Polished",
                      "Door Jams",
                      "Windows (inside & out)",
                    ]}
                  />
                  <PriceBlock
                    title="Gold"
                    prices={[
                      {
                        vehicle: "Small Car",
                        amount: 160.0,
                      },
                      {
                        vehicle: "Medium Car",
                        amount: 190.0,
                      },
                      {
                        vehicle: "Large Car / SUV",
                        amount: 230.0,
                      },
                    ]}
                    services={[
                      "Hand Wash",
                      "Gloss Finish",
                      "Mag Wheels Cleaned & Dressed",
                      "Door Frames",
                      "Vacuum Interior",
                      "Dashboard Cleaned & Polished",
                      "Door Jams",
                      "Windows (inside & out)",
                      "Tar Removal",
                      "Hand Wax",
                      "Shampoo/ Steam Clean Seats & Mats",
                      "Leather Clean & Conditioner"
                    ]}
                  />
                  <PriceBlock
                    title="Platinum"
                    prices={[
                      {
                        vehicle: "Small Car",
                        amount: 400.0,
                      },
                      {
                        vehicle: "Medium Car",
                        amount: 450.0,
                      },
                      {
                        vehicle: "Large Car / SUV",
                        amount: 550.0,
                      },
                    ]}
                    services={[
                      "Hand Wash",
                      "Gloss Finish",
                      "Mag Wheels Cleaned & Dressed",
                      "Door Frames",
                      "Vacuum Interior",
                      "Dashboard Cleaned & Polished",
                      "Door Jams",
                      "Windows (inside & out)",
                      "Tar Removal",
                      "Hand Wax",
                      "Shampoo/ Steam Clean Seats & Mats",
                      "Leather Clean & Conditioner",
                      "Full Cut & Polish / Paint Correction",
                      "Engine Bay Detail",
                    ]}
                  />
                </div>
                <div className="services__content__inner md:flex md:-mx-2 ">
                <PriceBlock
                    title="Additional Services"
                    prices={[
                      {
                        vehicle: "Small Car",
                        amount: "POA",
                      },
                      {
                        vehicle: "Medium Car",
                        amount: "POA",
                      },
                      {
                        vehicle: "Large Car / SUV",
                        amount: "POA",
                      },
                    ]}
                    services={["Clay Whole Car",
                      "Machine Cut & Polish",
                      "Engine Bay Clean & Dressed",
                      "Pet Hair Removal",
                      "Bad Odour Removal",
                      "Chrome Polish",
                      "Water marks removal",
                      "Stone chip removal",
                      "Signage removal",
                      "Color restoration",
                      "Home sofas/couches steam clean",
                      "Leather clean & condition"]}
                  />
                  <PriceBlock
                    title="Interior Steam Clean / Shampoo"
                    prices={[
                      {
                        vehicle: "Small Car",
                        amount: 130.0,
                      },
                      {
                        vehicle: "Medium Car",
                        amount: 150.0,
                      },
                      {
                        vehicle: "Large Car / SUV",
                        amount: 180.0,
                      },
                    ]}
                  />
                </div>
              </>
            )}
            {/* Tab 3 */}
            {props.activeTab === 2 && (
              <div className="services__content__inner md:flex md:-mx-2">
                <p>Please get in touch with us using our contact form below for more details.</p>
              </div>
            )}
            {/* Tab 2 */}
            {props.activeTab === 1 && (
              <>
              <div className="services__content__inner md:flex md:-mx-2">
                <PriceBlock title="Small Car" priceLarge={800.0} />
                <PriceBlock title="Medium Car" priceLarge={900.0} />
                <PriceBlock title="Large Car / SUV" priceLarge={1000.0} />
              </div>
              <p>* price may vary based on condition of paint surface.</p>
              </>
            )}
            {/* Tab 4 */}
            {props.activeTab === 3 && (
              <div className="services__content__inner md:flex md:-mx-2">
                <p>Please get in touch with us using our contact form below for more details.</p>
              </div>
            )}
          </div>
        </div>
        <p className="text-center mb-6">
          <span className="text-bold">Please note:</span> All prices are
          excluding GST.<br />
          *Additional charges for extra dirty vehicles, you will be notified prior to the service if this applies.<br />
          *Christchurch charges may be additional based on distance and condition of the vehicle.
        </p>
      </div>
    </div>
  );
};

export default Tabs;
