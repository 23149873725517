import React from "react"
import ReCAPTCHA from "react-google-recaptcha"
import IconFb from "../../../assets/icons/icon-fb.svg";
import IconInsta from "../../../assets/icons/icon-insta.svg";
import IconTiktok from "../../../assets/icons/icon-tiktok.svg";
import IconPhone from "../../../assets/icons/icon-phone.svg";
import IconEmail from "../../../assets/icons/icon-email.svg"

const Contact = () => {
  return (
    <div className="contact py-10" id="contact">
      <div className="container">
        <h2 className="heading text-center">Contact Us</h2>
        <p className="mb-6 text-center">For any enquiries regarding our services, please contact at details below or get in touch via our contact form.</p>
        <div className="md:flex flex-1 max-w-4xl mx-auto">
          <div className="basis-2/3 md:mr-6">
            <form method="post" data-netlify="true" data-netlify-recaptcha="true" name="contact" className="contact-form text-center">
              <input type="hidden" name="form-name" value="contact" />
              <div className="flex">
                <label className="flex-1 mr-2">
                  <input type="text" name="name" id="name" placeholder="Name" />
                </label>
                <label className="flex-1">
                  <input type="email" name="email" id="email" placeholder="Email" />
                </label>
              </div>
              <div className="flex">
                <label className="flex-1 mr-2">
                  <input type="text" name="phone" id="phone" placeholder="Phone Number" />
                </label>
                <label className="flex-1">
                  <input type="text" name="subject" id="subject" placeholder="Subject" />
                </label>
              </div>
              <label>
                <textarea name="message" id="message" rows="5" placeholder="Message" />
              </label>
              <ReCAPTCHA sitekey="6Le3hcoeAAAAAK5t64TwR4tVr1v5o5Pz6cMZNULs" />
              <br />
              <button type="submit" className="button button--large button--primary">Send</button>
              <button type="reset" className="button button--large button--secondary">Clear</button>
          </form>
          </div>
          <div className="basis-1/3">
          <div className="mb-4 flex-1">
              <h4 className="text-xl font-semibold mb-2">Auckland</h4>
              <p className="flex mb-1">
              <IconPhone />:  <a className="styled-link ml-2" href="tel:020 4157 2294">020 4157 2294</a>
              </p>
              <p className="flex ">
                <IconEmail />:  <a className="styled-link ml-2" href="mailto:info@thedetailingghost.co.nz">info@thedetailingghost.co.nz</a></p>
            </div>
            <div className="mb-4 flex-1">
              <h4 className="text-xl font-semibold mb-2">Christchurch</h4>
              <p className="flex mb-1 ">
               <IconPhone />:  <a className="styled-link ml-2" href="tel:022 171 0076">022 171 0076</a>
              </p>
              <p className="flex mb-4">
               <IconEmail />:  <a className="styled-link ml-2" href="mailto:christchurch@thedetailingghost.co.nz">christchurch@thedetailingghost.co.nz</a></p>
            </div>
          <div className="mb-4 flex-1">
              <h4 className="text-lg mb-2">Follow Us</h4>
              {/* <p className="flex mb-1">
              <IconFb />: <a className="styled-link ml-2" href="#" target="_blank">Facebook</a>
              </p> */}
              <p className="flex mb-1">
              <IconInsta />: <a className="styled-link ml-2" href="https://www.instagram.com/the_detailing_ghost/" target="_blank">Instagram</a>
              </p>
              <p className="flex mb-4">
              <IconTiktok />:  <a className="styled-link ml-2" target="_blank" href="https://www.tiktok.com/@karansinghnz">Tiktok</a></p>
            </div>
          </div>
          
        </div>
        <div>
        </div>
      </div>


    </div>

  )
}

export default Contact